@media screen and (max-width: 600px) {
  .navbar-brand {
    font-size: 1.5em;
  }
  .navbar-nav {
    flex-direction: column;
  }
  .navbar-nav .nav-link {
    padding: 15px;
    text-align: center;
  }
  .navbar-nav .nav-link:hover {
    color: #007bff;
  }
  .navbar-nav .active {
    color: #007bff;
  }
  .navbar-nav .active:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus {
    color: #007bff;
  }
  .navbar-nav .nav-link:active {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:active:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active {
    color: #007bff;
  }
  .navbar-nav .nav-link:active:focus {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active:focus {
    color: #007bff;
  }
  .navbar-nav .nav-link:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:active:focus:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active:focus:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:active:focus:active:focus:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav .nav-link:focus:active:focus:active:focus:active:focus:hover {
    color: #007bff;
  }

  .navbar-nav
    .nav-link:active:focus:active:focus:active:focus:active:focus:hover {
    color: #007bff;
  }
  .navbar-nav
    .nav-link:focus:active:focus:active:focus:active:focus:active:focus:hover {
    color: #007bff;
  }
}

@media (max-width: 480px) {
  .page-services {
    padding: 9px 7px 0 6px !important;
  }
  .page-services .page-services-nav .nav-item .active-link {
    color: #fff;
    background-color: var(--main-text-color);
    /* height: 55px;
    font-size: 14px; */
    text-align: center;
    justify-content: center;
    margin: auto;
    /* padding: 5px; */
  }
  .col-2 {
    flex: 0 0 auto;
    width: 33.666667% !important;
  }

  .page-services .page-services__gallery div[class^="col"] {
    margin-bottom: 9px;
    margin-left: -1px;
    margin-right: auto;
  }
  .page-services .page-services-nav .nav-item .nav-link {
    padding: 8px;
  }

  .col-9 {
    flex: 0 0 auto;
    width: 75%;
    /* margin-left: 50px; */
    justify-content: center;
    text-align: center;
    margin: auto;
  }
  .page-services .page-services__btn {
    border-radius: 50px;
    border: 1px solid transparent;
    width: 196px;
    height: 40px;
    color: #fff;
    background-color: var(--main-text-color);
    /* margin-left: 25px; */
  }
}
