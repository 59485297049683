//@media (max-width: 1000px) {
//}
//
//@media (max-width: 580px) {
//  * {
//    //background-color:   red;
//  }
//}
//
//@media (max-width: 450px) {
//}

@media only screen and (min-width: 600px) and (max-width: 808px) {
  * {
    //background-color: red;
  }
  .header {
    padding: 0 20px !important;

    .header-logo {
      .logo-with-text {
        display: none;
      }

      .logo-without-text {
        display: block;
        height: 80px;
      }
    }

    .header-navbar {
      display: none;
      padding-left: 0;
    }

    .header-contact {
      width: 600px;
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      margin-left: 20px;
    }

    .header-navbar__ul {
      display: block;
      position: absolute;
      border: 1px solid red;
    }

    .header-mobile-menu-btn {
      margin-left: -20px;
      margin-right: 20px;
      display: block;
    }
  }

  .first-section {
    //height: 158vh;
    padding: 0 20px !important;

    .first-section-left {
      margin-top: -140px;

      .first-section__sub-title {
        padding-right: 10px;
      }
    }

    .first-section-right {
      .first-section-img {
        background-position: 77% 20px;
        background-size: 120%;
        background-repeat: no-repeat;
        height: 740px;

        img {
          position: relative;
          top: 15px;
          left: 0;
          height: 570px;
        }
      }
    }

    .shadow-effect,
    .effect-position-2,
    .effect-position-3 {
      display: none;
    }
  }

  .about_and_team-sections {
    background-position: 500px 22px;
    background-size: 40%;

    .about-us-section {
      padding: 0 20px !important;

      .about-us-section__doodle {
        display: none;
      }

      .about-section-left {
        background-color: #fff;
        height: 630px;
        width: 37%;
        padding: 40px 0;

        img {
          width: 300px;
        }
      }

      .about-section-right {
        margin-top: -100px;
        width: 61%;
      }
    }

    .our-team-section {
      padding: 0 20px !important;
      margin-top: -70px;
      margin-bottom: -100px;
      .our-team-section__carousel {
        margin: 30px 72px;

        .team-card {
          width: 250px;
          padding: 20px;
          border-radius: 20px;
          background-color: #fff;
          box-shadow: 0 9px 15px 3px #ddecf6;

          .team-card__img {
            border-radius: 50%;
            overflow: hidden;
            width: 170px;
            margin: 10px auto;
            height: 170px;

            img {
              width: 200px;
            }
          }

          .team-card__name {
            color: var(--main-text-color);
          }

          .team-card__profession {
            color: #646464;
          }

          .team-card__btn {
            background-color: var(--main-text-color);
            color: #fff;
            border: none;
            width: 100%;
            border-radius: 50px;
            padding: 9px;
          }
        }
      }
    }
  }

  .services-section {
    padding: 0 20px !important;

    .effect-position-5 {
      display: none;
    }
  }

  .trusted-us {
    padding: 0 50px !important;

    .trusted-us__title {
      font-weight: bold;
      text-align: center;
      padding: 100px 0 50px 0;
    }

    .trusted-us__body {
      .trusted-us-card {
        margin-left: 110px;
        margin-bottom: 20px;

        img {
          height: 60px;
          width: auto;
          max-width: 240px;
        }
      }
    }
  }

  .form {
    padding: 80px 20px 0 20px !important;

    .form-left {
      .form-title {
        margin-top: 10px;
        font-weight: bold;
      }

      .form-description {
        padding: 12px 0;
        font-size: 0.95em;
      }

      .form-inputs {
        display: flex;
        row-gap: 15px;
        flex-direction: column;

        input {
          border: 1px solid var(--main-text-color);
          border-radius: 50px;
          padding: 7px 0;
          width: 100%;
          text-indent: 20px;
        }

        button {
          border: 1px solid var(--main-text-color);
          background-color: var(--main-text-color);
          color: #fff;
          border-radius: 50px;
          padding: 8px 35px;
        }
      }
    }

    .form-right {
      text-align: center;
      margin-top: 22px;

      img {
        width: 230px;
      }
    }
  }

  .footer {
    background-color: var(--main-body-color);
    color: #eee;
    padding: 48px 20px !important;
    background-repeat: no-repeat;
    background-position: 0 0px;
    background-size: 80%;
    margin-top: 90px;

    .footer-description {
      padding-right: 45px;
      margin-bottom: 27px;
    }

    .footer-social-nets {
      padding-top: 38px;
    }
  }
}

@media only screen and (max-width: 700px) {
  * {
    //border: 1px solid red;
  }

  .header {
    padding: 0 20px !important;

    .header-logo {
      width: 19%;

      .logo-with-text {
        display: none;
      }

      .logo-without-text {
        display: block;
        height: 80px;
      }
    }

    .header-navbar {
      display: none;
      padding-left: 0;
    }

    .header-contact {
      width: 72% !important;
      margin: 0 !important;
    }

    .header-navbar__ul {
      display: block;
      position: absolute;
      border: 1px solid red;
    }

    .header-mobile-menu-btn {
      margin-left: -20px;
      margin-right: 20px;
      display: block;
    }
  }

  .first-section {
    //height: 188vh !important;
    padding: 0 20px !important;

    .first-section-left {
      margin-top: -140px;

      .first-section__sub-title {
        padding-right: 10px;
      }
    }

    .first-section-right {
      .first-section-img {
        background-position: 77% 20px;
        background-size: 120%;
        background-repeat: no-repeat;
        height: 740px;

        img {
          position: relative;
          top: 15px;
          left: 0;
          height: 570px;
        }
      }
    }

    .shadow-effect,
    .effect-position-2,
    .effect-position-3 {
      display: none;
    }
  }

  .about_and_team-sections {
    background-position: 400px 22px;
    background-size: 42%;

    .about-us-section {
      padding: 0 20px !important;

      .about-us-section__doodle {
        display: none;
      }

      .about-section-left {
        background-color: #fff;
        //height: 630px;
        //width: 37%;
        padding: 30px 0;

        img {
          width: 250px;
        }
      }

      .about-section-right {
        margin-top: -20px;
        //width: 62%;
      }
    }

    .our-team-section {
      padding: 0 20px !important;
      margin-top: -20px;

      .our-team-section__carousel {
        margin: 30px 50px;

        .team-card {
          width: 250px;
          padding: 20px;
          margin: 20px 5px 20px 10px;
          border-radius: 20px;
          background-color: #fff;
          box-shadow: 0 9px 15px 3px #ddecf6;

          .team-card__img {
            border-radius: 50%;
            overflow: hidden;
            width: 170px;
            margin: 10px auto;
            height: 170px;

            img {
              width: 200px;
            }
          }

          .team-card__name {
            color: var(--main-text-color);
          }

          .team-card__profession {
            color: #646464;
          }

          .team-card__btn {
            background-color: var(--main-text-color);
            color: #fff;
            border: none;
            width: 100%;
            border-radius: 50px;
            padding: 9px;
          }
        }
      }
    }
  }

  .footer {
    padding: 35px 40px 35px 50px !important;
    background-repeat: no-repeat;
    background-position: 0 -30px;
    margin-top: 60px;

    .footer-copyright-mobile {
      display: block !important;
      text-align: center;
      padding-top: 35px;
    }

    .footer-logo {
      text-align: center;
      margin-bottom: 27px;
    }

    .footer-description {
      padding-right: 0 !important;
      margin-bottom: 27px;
    }

    .footer-copyright {
      display: none;
    }

    .footer-social-nets {
      padding-top: 66px;
    }
  }
}

@media only screen and (max-width: 600px) {
  * {
    //border: 1px solid red;
  }

  .header {
    .header-logo {
      .logo-with-text {
        display: none;
      }

      .logo-without-text {
        display: block;
        height: 80px;
      }
    }

    .header-contact {
      padding-left: 20px;
    }

    .header-mobile-menu-btn {
      margin-left: -20px;
      margin-right: 20px;
      display: block;
    }
  }

  .first-section {
    height: 100% !important;
    padding: 0 20px 40px 20px !important;

    .first-section-left {
      margin-top: 40px !important;

      .first-section__sub-title {
        padding-right: 10px;
      }
    }

    .first-section-right {
      display: none;
    }

    .shadow-effect,
    .effect-position-2,
    .effect-position-3 {
      display: none;
    }
  }

  .about_and_team-sections {
    background-position: 80px 52px !important;
    background-size: 90%;

    .about-us-section {
      padding: 20px !important;

      .about-us-section__doodle {
        display: none;
      }

      .about-section-left {
        display: none;
      }

      .about-section-right {
        margin-top: 15px !important;
      }
    }

    .our-team-section {
      padding: 0 20px !important;
      margin-top: 40px;

      .our-team-section__carousel {
        margin: 20px 10px 20px 30px;

        .team-card {
          width: 250px;
          padding: 20px;
          border-radius: 20px;
          background-color: #fff;
          box-shadow: 0 9px 15px 3px #ddecf6;

          .team-card__img {
            border-radius: 50%;
            overflow: hidden;
            width: 170px;
            margin: 10px auto;
            height: 170px;

            img {
              width: 200px;
            }
          }

          .team-card__name {
            color: var(--main-text-color);
          }

          .team-card__profession {
            color: #646464;
          }

          .team-card__btn {
            background-color: var(--main-text-color);
            color: #fff;
            border: none;
            width: 100%;
            border-radius: 50px;
            padding: 9px;
          }
        }
      }
    }
  }

  .services-section {
    padding: 0 20px !important;

    .services-section__title {
      padding: 40px 0 0 0;
      margin-bottom: -20px !important;
      text-align: center;
    }

    .service-cards {
      padding-bottom: 40px;

      div[class^="col"] > div {
        text-align: center;
        margin: 45px 0 0 0;

        .service-card__icon {
          height: 150px;
          padding: 83px 0;
          background-position: center;
          background-size: 44%;
          background-repeat: no-repeat;
        }

        .service-card__title {
          font-weight: 600;
          font-size: 24px;
          padding: 20px 0 10px 0;
        }

        .service-card__text {
          color: var(--main-text-color);
          padding: 0 25px;
        }
      }
    }

    .effect-position-5 {
      display: none;
    }
  }

  .projects-section {
    padding: 0 20px !important;

    .projects-head {
      padding: 60px 0 30px 0;

      .projects-head__title {
        width: 80%;
      }
    }
  }

  .trusted-us {
    padding: 0 20px !important;

    .trusted-us__body {
      .trusted-us-card {
        margin-left: 130px;

        img {
          height: 60px;
          width: auto;
          max-width: 240px;
        }
      }
    }

    .effect-position-6 {
      top: -290px !important;
      left: -20px;
    }
  }

  .form {
    padding: 80px 30px 0 30px !important;

    .form-left {
      .form-title {
        //border: 1px solid red;
        font-weight: bold;
      }

      .form-description {
        padding: 19px 0;
      }

      .form-inputs {
        display: flex;
        row-gap: 20px;
        padding: 0;
        flex-direction: column;

        input {
          border: 1px solid var(--main-text-color);
          border-radius: 50px;
          padding: 7px 0;
          width: 100%;
          text-indent: 20px;
        }

        button {
          border: 1px solid var(--main-text-color);
          background-color: var(--main-text-color);
          color: #fff;
          border-radius: 50px;
          padding: 8px 35px;
        }
      }
    }

    .form-right {
      display: none;
    }
  }

  .footer {
    padding: 35px 40px !important;
    background-repeat: no-repeat;
    background-position: -20px 30px;
    background-size: 140% 100%;
    margin-top: 60px;

    .footer-logo {
      text-align: center;
      margin-bottom: 27px;
    }

    .footer-description {
      padding-right: 0 !important;
      margin-bottom: 27px;
    }

    .footer-copyright {
      display: none;
    }

    .footer-information {
      text-align: center;
      margin-top: 25px;

      .footer-information-title {
        font-size: 1.5em;
      }

      a {
        font-size: 1.2em;
      }
    }

    .footer-contact {
      text-align: center;
      margin-top: 50px;

      .footer-contact-title {
        font-size: 1.5em;
      }

      a {
        font-size: 1.2em;
      }
    }

    .footer-social-nets {
      text-align: center;
      padding: 30px 0;
      justify-content: center;
    }

    //.footer-information {
    //  margin-left: 50px;
    //  margin-right: -50px;
    //
    //  .footer-information-title {
    //    font-weight: bold;
    //    margin-bottom: 16px;
    //  }
    //
    //  a {
    //    display: block;
    //    padding: 4px 0;
    //    text-decoration: none;
    //    color: #eee;
    //  }
    //}

    //.footer-contact {
    //  .footer-contact-title {
    //    margin-bottom: 16px;
    //  }
    //
    //  .footer-contact-tel,
    //  .footer-contact-email {
    //    padding: 4px 0;
    //
    //    a {
    //      text-decoration: none;
    //      color: #fff;
    //
    //      svg {
    //        margin-right: 6px;
    //        margin-top: -2px;
    //
    //        path {
    //          fill: #fff;
    //        }
    //      }
    //
    //      &:hover {
    //        color: #ccc;
    //
    //        svg {
    //          path {
    //            fill: #ccc;
    //          }
    //        }
    //      }
    //    }
    //  }
    //
    //}

    //.footer-social-nets {
    //  display: flex;
    //  column-gap: 25px;
    //  padding-top: 66px;
    //}
  }
}

@media only screen and (min-width: 808px) and (max-width: 992px) {
  //* {
  //  border: 1px solid red;
  //}
  .header {
    padding: 0 30px !important;

    .header-logo {
      .logo-with-text {
        display: none;
      }

      .logo-without-text {
        display: block;
        height: 80px;
      }
    }

    .header-navbar {
      padding-left: 0;
    }
  }

  .first-section {
    //height: 150vh;
    padding: 0 30px !important;

    .first-section-left {
      margin-top: -140px;

      .first-section__sub-title {
        padding-right: 10px;
      }
    }

    .first-section-right {
      .first-section-img {
        background-position: 77% 20px;
        background-size: 120%;
        background-repeat: no-repeat;
        height: 740px;

        img {
          position: relative;
          top: 15px;
          left: 0;
          height: 570px;
        }
      }
    }

    .shadow-effect {
      border-radius: 50%;
      position: absolute;
      box-shadow: 0 0 200px 90px #255781 !important;
    }

    .effect-position-2 {
      bottom: -180px;
      right: 200px;
      background-color: transparent;
      box-shadow: 0 0 180px 80px #255781 !important;
    }

    .effect-position-3 {
      width: 320px;
      position: absolute;
      right: 50px;
      height: 5px;
      bottom: -279px;
      border-radius: 50%;
      box-shadow: 0 -80px 13px 2px rgba(10, 10, 10, 0.5);
    }
  }

  .about_and_team-sections {
    background-position: 530px 22px;
    background-size: 40%;

    .about-us-section {
      padding: 0 30px !important;

      .about-section-left {
        background-color: #fff;
        height: 630px;
        width: 43%;
        padding: 20px 0;

        img {
          width: 400px;
        }
      }
    }

    .our-team-section {
      padding: 0 30px !important;

      .our-team-section__carousel {
        margin: 30px 0;

        .team-card {
          width: 250px;
          padding: 20px;
          border-radius: 20px;
          margin: 20px 0 20px 60px;
          background-color: #fff;
          box-shadow: 0 9px 15px 3px #ddecf6;

          .team-card__img {
            border-radius: 50%;
            overflow: hidden;
            width: 170px;
            margin: 10px auto;
            height: 170px;

            img {
              width: 200px;
            }
          }

          .team-card__name {
            color: var(--main-text-color);
          }

          .team-card__profession {
            color: #646464;
          }

          .team-card__btn {
            background-color: var(--main-text-color);
            color: #fff;
            border: none;
            width: 100%;
            border-radius: 50px;
            padding: 9px;
          }
        }
      }
    }
  }

  .services-section {
    padding: 0 30px !important;

    .effect-position-5 {
      display: none;
    }
  }

  .trusted-us {
    padding: 0 30px !important;

    .trusted-us__title {
      font-weight: bold;
      text-align: center;
      padding: 100px 0 50px 0;
    }

    .trusted-us__body {
      .trusted-us-card {
        margin-left: 110px;
        margin-bottom: 20px;

        img {
          height: 60px;
          width: auto;
          max-width: 240px;
        }
      }
    }
  }

  .form {
    padding: 80px 30px 0 30px !important;

    .form-left {
      .form-title {
        //border: 1px solid red;
        font-weight: bold;
      }

      .form-description {
        padding: 19px 0;
      }

      .form-inputs {
        display: flex;
        column-gap: 17px;

        input {
          border: 1px solid var(--main-text-color);
          border-radius: 50px;
          padding: 7px 0;
          width: 240px;
          text-indent: 20px;
        }

        button {
          border: 1px solid var(--main-text-color);
          background-color: var(--main-text-color);
          color: #fff;
          border-radius: 50px;
          padding: 8px 35px;
        }
      }
    }

    .form-right {
      img {
        margin-right: -30px;
        width: 220px;
      }
    }

    .form-right {
      //border: 1px solid red;
      text-align: center;
      margin-top: -40px;

      img {
        width: 260px;
      }
    }
  }

  .footer {
    background-color: var(--main-body-color);
    color: #eee;
    padding: 48px 30px !important;
    background-repeat: no-repeat;
    background-position: 0 -30px;
    margin-top: 90px;

    .footer-description {
      padding-right: 55px;
      margin-bottom: 27px;
    }

    .footer-social-nets {
      padding-top: 63px !important;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header {
    padding: 0 30px !important;

    .header-navbar {
      padding-left: 0;
    }
  }

  .first-section {
    //height: 125vh;
    padding: 0 30px !important;

    .first-section-left {
      margin-top: -140px;

      .first-section__sub-title {
        padding-right: 10px;
      }
    }

    .first-section-right {
      .first-section-img {
        background-position: 77% 50px;
        background-size: 120%;
        background-repeat: no-repeat;
        height: 740px;

        img {
          position: relative;
          top: 50px;
          left: 0;
          height: 570px;
        }
      }
    }
  }

  .about_and_team-sections {
    background-position: 550px 15px;
    background-size: 50%;

    .about-us-section {
      padding: 0 80px !important;

      .about-section-left {
        background-color: #fff;
        height: 630px;

        img {
          width: 400px;
        }
      }
    }
  }

  .footer {
    background-color: var(--main-body-color);
    color: #eee;
    padding: 48px 30px !important;
    background-repeat: no-repeat;
    background-position: 0 -30px;
    margin-top: 100px;

    .footer-description {
      padding-right: 85px;
      margin-bottom: 27px;
    }

    .footer-social-nets {
      padding-top: 63px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .first-section {
    .first-section-left {
      margin-top: -140px;

      .first-section__sub-title {
        padding-right: 20px;
      }
    }

    .first-section-right {
      .first-section-img {
        background-position: 77% 50px;
        background-size: 120%;
        background-repeat: no-repeat;
        height: 740px;

        img {
          position: relative;
          top: 50px;
          left: 0;
        }
      }
    }
  }
  .footer {
    .footer-description {
      padding-right: 85px;
      margin-bottom: 27px;
    }

    .footer-social-nets {
      padding-top: 63px !important;
    }
  }
}
