.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  mask-repeat: no-repeat;
  mask-size: 100%;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  -webkit-mask-position: center;
  vertical-align: middle;
  background-color: #eee;

  &.icon-home {
    mask-image: url("../Components/icons/home-icon.svg");
    -webkit-mask-image: url("../Components/icons/home-icon.svg");
  }

  &.icon-project {
    mask-image: url("../Components/icons/project-icon.svg");
    -webkit-mask-image: url("../Components/icons/project-icon.svg");
  }

  &.icon-service {
    mask-image: url("../Components/icons/service-icon.svg");
    -webkit-mask-image: url("../Components/icons/service-icon.svg");
  }

  &.icon-trusted-us {
    mask-image: url("../Components/icons/trusted-icon.svg");
    -webkit-mask-image: url("../Components/icons/trusted-icon.svg");
  }

}

.admin-layout {
  width: 100%;
  position: relative;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  transition: all .3s ease;

  .admin-header {
    box-shadow: 0 0 6px 1px rgba(100, 100, 100, .7);
    position: fixed;
    width: calc(100% - 250px);
    margin: 0 0 0 250px;
    height: 45px;
    display: flex;
    z-index: 500;
    background-color: #fff;
    justify-content: space-between;

    .admin-header-user {
      font-weight: bold;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 40px;

      & > svg {
        margin-right: 20px;
      }

      .admin-header-user-avatar {
        box-shadow: 0 1px 1px rgba(10, 10, 10, 1);
        background-size: cover;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        padding: 10px;
        margin-right: 9px;
        font-weight: bold;
      }
    }
  }

  .admin-sidebar {
    z-index: 5;
    width: 250px;
    top: 0;
    background-color: #222D32;
    position: fixed;
    height: 100vh;

    .admin-sidebar__head {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }

    .admin-sidebar__body {
      padding: 20px 0 0 0;
      color: #eee;

      .admin-sidebar__menu {
        padding: 17px 0;


        div[class^="admin-sidebar__menu__big"] {
          transition: 1s;

          //************** MENU LINKS **************
          .new-link {
            user-select: none;
            cursor: pointer;
            //border: 1px solid blue;
            padding: 10px 25px 9px 20px;
            color: #eee !important;
            text-decoration: none;
            border-left: 4px solid transparent;
            display: flex;
            justify-content: space-between;

            span[class^="icon"] {
              margin-right: 13px;
              margin-left: 15px;
              position: relative;
              top: -1px;
            }

            &:hover {
              background-color: #1E282C;
              border-color: var(--main-color);
            }

            .new-link__amount {
              background-color: var(--main-color);
              padding: 2px 6px;
              border-radius: 4px;
              font-size: .89em;

            }
          }

          .active-new-link {
            background-color: var(--main-color);
            border-left: 4px solid var(--main-color);

            &:hover {
              background-color: var(--main-color);
            }

            .new-link__arrow {

              svg {
                transition: .3s;
                transform: rotate(90deg) !important;
              }
            }
          }

          .not-active-new-link {
            .new-link__arrow {
              svg {
                transition: .3s;
                transform: rotate(-90deg) !important;
              }
            }
          }

        }

        .admin-sidebar__menu__middle,
        .admin-sidebar__menu__small {
          height: 0;
          overflow: hidden;
          //transition: .1s;
        }
      }
    }
  }

  .admin-main-body {
    width: calc(100% - 250px);
    margin-left: 250px;
    margin-top: 45px;
  }

}