.not-found-page {
  background-color: var(--main-body-color);
  height: 100vh;


  .ball1 {
    background-color: var(--main-text-color);
    border-radius: 50%;
    z-index: 999;
    position: relative;
    top: 8%;
    margin-left: -100px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 140px 90px var(--main-text-color);
  }

  .ball2 {
    border-radius: 50%;
    background-color: var(--main-text-color);
    position: relative;
    top: 62%;
    margin-left: 570px;
    width: 80px;
    height: 80px;
    box-shadow: 0 0 140px 90px var(--main-text-color);
  }

  .ntf-modal {
    border-radius: 4px;
    backdrop-filter: blur(17px);
    position: fixed;
    padding-top: 100px;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    text-align: center;

    h1 {
      margin-top: 60px;
      color: #fff;
    }

    .ntf-modal-foot {
      display: flex;
      padding: 70px 0;
      justify-content: center;

      .ntf-link {
        margin: 0 10px;
      }

      button {
        background-color: var(--main-text-color);
        padding: 19px 17px;
        border-radius: 4px;
        border: none;
        width: 230px;
        transition: box-shadow .4s, transform .2s;
        font-size: 1.2em;
        color: #fff;
        outline: none;

        &:hover {
          box-shadow: 0 3px 23px var(--main-text-color);
        }
        &:active {
          transform: scale(.95);
        }

        img {
          width: 30px;
          margin-top: -2px;
          margin-right: 12px;
        }
        &.ntf-page-btn-to-main {
          img {
            width: 30px;
            margin-top: -2px;
            margin-left: 12px;
            margin-right: -5px;
          }
        }
      }
    }
  }
}
