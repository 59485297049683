/*      FIRST START        */
.first-section {
  background-color: var(--main-body-color);
  color: #fff;
  //height: 125vh;
  padding: 0 80px !important;

  .first-section-left {
    display: flex;
    align-self: center;
    flex-direction: column;
    margin-top: -90px;

    .first-section__title {
      font-size: 4.2em;
      font-weight: bold;
      letter-spacing: 2px;
      color: var(--main-text-color);
    }

    .first-section__sub-title {
      letter-spacing: 2px;
      font-weight: bold;
      padding-right: 190px;
    }

    .first-section-description {
      padding: 6px 0;
    }

    .first-section__btn {
      border-radius: 50px;
      border: 1px solid transparent;
      width: 196px;
      text-decoration: none;
      height: 40px;
      padding: 6px 0;
      text-align: center;
      color: #fff;
      background-color: var(--main-text-color);
      transition: 0.2s;

      &:hover {
        box-shadow: 0 1px 10px var(--main-text-color);
      }

      .first-section-btn__arrow {
        border: 5px solid transparent;
        border-left: 5px solid #fff;
        font-size: 0;
        position: relative;
        margin-left: 15px;
        top: -4.5px;
      }
    }
  }

  .first-section-right {
    .first-section-img {
      background-image: url("../../utilities/images/first-section-2.png");
      background-position: 53% -20px;
      background-repeat: no-repeat;
      height: 740px;

      img {
        position: relative;
        top: -20px;
        width: 100%;
        left: 0;
      }
    }
  }

  .shadow-effect {
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 200px 90px #255781 !important;
  }

  .effect-position-1 {
    top: 220px;
    left: 60px;
  }

  .effect-position-2 {
    bottom: -140px;
    right: 320px;
    background-color: transparent;
    box-shadow: 0 0 180px 80px #255781 !important;
  }

  .effect-position-3 {
    width: 320px;
    position: absolute;
    right: 180px;
    height: 5px;
    bottom: -205px;
    border-radius: 50%;
    box-shadow: 0 -80px 13px 2px rgba(10, 10, 10, 0.5);
  }
}

/*      FIRST END        */

/*      ABOUT US AND TEAM START        */
.about_and_team-sections {
  background-image: url("../../utilities/images/background-logo-2.svg");
  background-position: 700px 45px;
  background-repeat: no-repeat;

  .about-us-section {
    padding: 0 80px !important;

    .about-us-section__doodle {
      img {
        right: 22px;
        margin-top: 30px;
        width: 103px;
        height: 103px;
        position: absolute;
      }
    }

    .about-section-left {
      background-color: #fff;
    }

    .about-section-right {
      display: flex;
      align-self: center;
      flex-direction: column;
      margin-top: -60px;
    }
  }

  .our-team-section {
    padding: 0 80px !important;
    background-image: linear-gradient(to bottom, transparent, #e3f4ff);
    margin-bottom: -100px;
    .our-team-section__title {
      text-align: center;
      font-weight: bold;
    }

    .our-team-section__carousel {
      margin: 30px 0;

      .team-card {
        width: 250px;
        padding: 20px;
        border-radius: 20px;
        margin: 20px 5px 20px 20px;
        background-color: #fff;
        transition: 0.08s;
        box-shadow: 0 9px 15px 3px #ddecf6;

        .team-card__img {
          border-radius: 50%;
          overflow: hidden;
          width: 170px;
          margin: 10px auto;
          height: 170px;

          img {
            width: 200px;
          }
        }

        .team-card__name {
          color: var(--main-text-color);
          font-size: 13px;
        }

        .team-card__profession {
          color: #646464;
        }

        .team-card__btn {
          background-color: var(--main-text-color);
          color: #fff;
          border: none;
          width: 100%;
          border-radius: 50px;
          padding: 9px;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .our-team-section__foot {
      text-align: center;
      width: 320px;
      padding: 40px 0 80px 0;
      margin: auto;
    }
  }
}

/*      ABOUT US AND TEAM END        */

/*      SERVICES START        */
.services-section {
  padding: 0 80px !important;
  background-color: var(--main-body-color);
  color: #fff;
  background-image: url("../../utilities/images/background-logo-3.svg");
  background-repeat: no-repeat;
  background-position: 0% 100%;

  .services-section__title {
    padding: 45px 0;
    text-align: center;
  }

  .service-cards {
    padding-bottom: 40px;

    div[class^="col"] > div {
      text-align: center;
      margin: 40px 0;

      .service-card__icon {
        height: 150px;
        padding: 83px 0;
        background-position: center;
        background-size: 44%;
        background-repeat: no-repeat;
      }

      .service-card__title {
        font-weight: 600;
        font-size: 24px;
        padding: 20px 0 14px 0;
      }

      .service-card__text {
        color: var(--main-text-color);
        padding: 0 30px;
      }
    }

    .service-card-1 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-1.png");
      }
    }

    .service-card-2 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-2.png");
      }
    }

    .service-card-3 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-3.png");
      }
    }

    .service-card-4 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-4.png");
      }
    }

    .service-card-5 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-5.png");
      }
    }

    .service-card-6 {
      .service-card__icon {
        background-image: url("../../utilities/images/services-6.png");
      }
    }
  }

  .shadow-effect {
    border-radius: 50%;
    position: relative;
    width: 20px;
    box-shadow: 0 0 200px 90px #255781 !important;
  }

  .effect-position-4 {
    top: -440px;
    left: 0;
  }

  .effect-position-5 {
    top: -330px;
    left: 81%;
    box-shadow: 0 0 200px 120px #255781 !important;
  }
}

/*      SERVICES END        */

/*      SERVICES START        */
.projects-section {
  padding: 0 80px !important;

  .projects-head {
    padding: 60px 0 50px 0;
    display: flex;
    align-items: center;

    .projects-head__title {
      display: inline-block;
      font-weight: bold;
      width: 50%;
    }

    .projects-head__btns {
      width: 50%;
      margin: 0;
      height: 100% !important;
      text-align: right;
      display: inline-block;

      button {
        border-radius: 50%;
        border: none;
        color: #fff;
        margin: 0 4px;
        background-color: var(--main-text-color);
      }

      .projects-head__enabled-btn {
        transition: 0.4s;
        height: 50px;
        width: 50px;
        padding-bottom: 2px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .projects-head__disabled-btn {
        transition: 0.4s;
        opacity: 0.3;
        padding-bottom: 2px;
        width: 38px;
        height: 38px;

        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  .projects-container {
    .projects-slider {
      column-gap: 22px;
      overflow: hidden;
      display: flex;

      .project-card {
        background-color: #eaf7ff;
        transition: 0.4s;
        border-radius: 20px;
        height: 480px !important;
        position: relative;
        padding: 30px 21px 20px 21px;

        .project-card-img {
          border-radius: 10px;
          text-align: center;
          overflow: hidden;
          width: 275px;
          height: 290px;

          img {
            width: 275px;
            aspect-ratio: 1/1;
          }
        }

        .project-card-info {
          text-align: center;

          .project-card-title {
            color: #1e4058;
            font-weight: bold;
            padding: 23px 0 5px 0;
          }

          .project-card-text {
            font-size: 14px;
          }

          .project-card-button {
            background-color: var(--main-text-color);
            border-radius: 50px;
            margin: 19px 0 0 0;
            padding: 10px 0;
            width: 100%;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }
}

/*      SERVICES END        */

/*      TRUSTED US START        */
.trusted-us {
  padding: 0 80px !important;

  .trusted-us__title {
    font-weight: bold;
    text-align: center;
    padding: 100px 0 50px 0;
  }

  .trusted-us__body {
    .trusted-us-card {
      margin-bottom: 20px;

      img {
        height: 60px;
        width: auto;
        max-width: 240px;
      }
    }
  }

  .shadow-effect {
    border-radius: 50%;
    position: relative;
    z-index: -1;
  }

  .effect-position-6 {
    top: -170px !important;
    left: 0;
    width: 180px;
    box-shadow: 0 0 220px 90px #b5dff1 !important;
  }
}

/*      TRUSTED US END        */

/*      FORM START        */
.form {
  padding: 80px 80px 0 80px !important;

  .form-left {
    .form-title {
      //border: 1px solid red;
      font-weight: bold;
    }

    .form-description {
      padding: 19px 0;
    }

    .form-inputs {
      display: flex;
      column-gap: 17px;

      input {
        border: 1px solid var(--main-text-color);
        border-radius: 50px;
        padding: 7px 0;
        width: 240px;
        text-indent: 20px;
      }

      button {
        border: 1px solid var(--main-text-color);
        background-color: var(--main-text-color);
        color: #fff;
        border-radius: 50px;
        padding: 8px 35px;
      }
    }
  }

  .form-right {
    //border: 1px solid red;
    text-align: center;
    margin-top: -40px;

    img {
      width: 260px;
    }
  }
}

/*      FORM END        */
