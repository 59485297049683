.login-background {
  background-color: var(--main-body-color);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container {
    background-color: rgba(65, 94, 110, .7);
    box-shadow: 0 0 20px 1px rgba(40, 138, 185, .4);
    padding: 30px 20px;
    border-radius: 3px;
    width: 450px;

    .login-title {
      color: #fff;
      text-align: center;
    }

    .login__error-message {
      color: #fd2929;
      transition: .2s;
      text-align: center;
      opacity: 0;
      position: relative;
      top: -6px;
      margin-bottom: -23px;
    }

    .login__error-message-visible {
      opacity: 1;
      top: 0;
    }

    .login-form {
      padding: 20px 30px;

      label {
        color: #eee;
      }

      input {
        border-radius: 3px;
        border: none;
        color: #fff;
        background-color: #111;
      }

      button {
        margin-top: 25px;
      }
    }
  }
}