@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
.contact {
  background-color: #192232;
  height: 100vh;
}
.Ijtimoiy__tarmoqlar {
  height: 100vh;
  width: 86%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ijtimoiy__tarmoqlar .box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.Ijtimoiy__tarmoqlar .box .logo {
  width: 300px;
  height: 300px;
}
.Ijtimoiy__tarmoqlar_btns{
    display: block !important;
}
.Ijtimoiy__tarmoqlar_btns > a {
  background-color: #69d1fd;
  border: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  width: 225px;
  display: flex;
  justify-content: center;
  height: 47px;
  border-radius: 26px;
  margin-top: 20px;
  text-decoration: none !important;
  color: #192232 !important;
}
.Ijtimoiy__tarmoqlar_btns > a svg {
  background-color: transparent;
}
.Ijtimoiy__tarmoqlar_btns > a > .span {
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
}
@media (max-width: 767px) {
  .Ijtimoiy__tarmoqlar .box .logo {
    width: 45%;
    height: 45%;
  }
}
@media (max-width: 700px) {
  .Ijtimoiy__tarmoqlar .box .logo {
    width: 55%;
    height: 55%;
  }
  .Ijtimoiy__tarmoqlar .box {
    display: block;
    text-align: center;
  }
  .Ijtimoiy__tarmoqlar_btns > a {
    margin: 0 auto;
    margin-top: 20px;
  }
}
