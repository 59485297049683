.page-services {
  padding: 50px 80px 0 80px !important;

  .page-services-nav {
    display: flex;
    padding: 0;

    .nav-item {
      list-style-type: none;
      padding-right: 6px;

      .nav-link {
        border: 1px solid transparent;
        border-radius: 50px;
        background-color: #fff;
        color: var(--main-text-color);

        &:hover {
          border: 1px solid #ddd;
        }
      }

      .active-link {
        color: #fff;
        background-color: var(--main-text-color);

        &:hover {
          border: 1px solid var(--main-text-color);
        }
      }
    }
  }

  .page-services__btn {
    border-radius: 50px;
    border: 1px solid transparent;
    width: 196px;
    height: 40px;
    color: #fff;
    background-color: var(--main-text-color);

    .page-services__btn__arrow {
      border: 5px solid transparent;
      border-left: 5px solid #fff;
      font-size: 0;
      position: relative;
      margin-left: 15px;
      top: -4.5px;
    }
  }

  .page-services__gallery {
    padding: 20px 8px;

    div[class^="col"] {
      margin-bottom: 9px;

      img {
        width: 190px;
        height: 170px;
      }
    }
  }

  .page-services__tab-content {
    padding: 40px 0;
  }

  .page-services-foot {
    text-align: center;
    margin: 20px 0;
  }
}
