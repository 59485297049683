.header {
  background-image: linear-gradient(#23384C, #142637);
  padding: 0 80px !important;

  .header-logo {

    svg {
      height: 100%;
    }

    .logo-with-text {
      height: 100px;
      display: block;
    }

    .logo-without-text {
      display: none;
    }
  }


  .header-navbar {
    padding: 0;
    padding-left: 40px;

    ul {
      display: flex;
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;

      li {
        height: 100%;
        display: grid;
        align-items: center;

        a {
          text-decoration: none !important;
          height: 100% !important;
          color: var(--main-color);
          padding: 0 25px;
          display: flex;
          align-items: center;
          -webkit-transition: 0.3s;
          transition: 0.3s;
        }

        .active-navbar-link {
          color: #fff;

          &:after {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            position: relative;
            top: 17px;
            left: -50%;
            background-color: #fff;
          }
        }
      }
    }
  }

  .header-contact {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-self: center;

    .header-contact-tel,
    .header-contact-email {
      padding: 1px 0;

      a {
        text-decoration: none;
        color: var(--main-color);

        svg {
          margin-right: 6px;
          margin-top: -2px;
        }
      }
    }
  }

  .header-mobile-menu-btn {
    border: none;
    background-color: transparent;
    display: none;
  }

  .mobile-menu {
    position: absolute;
    padding: 10px 0;
    left: 6%;
    width: 88%;
    top: 70px;
    visibility: hidden;
    opacity: 1;
    z-index: 9000;
    background-color: rgba(45, 158, 183, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(45, 175, 231, .5);

    ul {
      padding: 0;

      li {
        list-style-type: none;

        &:last-child {
          margin-bottom: -8px !important;
        }

        a {
          text-decoration: none;
          width: 100%;
          color: #fff;
          display: block;
          padding: 14px 30px;

        }

        .active-navbar-link {
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            margin: 10.5px 0 0 -13px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
          }
        }
      }
    }

    //display: none;
  }

  .mobile-menu-hidden {
    visibility: hidden;
    opacity: 0;
    transform: translate(20px, -20px) scale(.9);
    transition: .09s;
  }

  .mobile-menu-visible {
    visibility: visible;
    opacity: 1;
    transition: .09s;
  }
}