* {
  outline-color: var(--main-color);
}

button {
  user-select: none;
}

.admin-page-universal-style {
  background-color: rgba(252, 252, 252, .9);
  background-color: #F7F7F7;
  height: 100%;
  padding: 30px;

  .admin-page-head {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .admin-page-head__title {
      color: var(--main-color);
      padding: 3px 10px 5px 10px;
    }

    .admin-page-head__btn {
      margin-right: 10px;

      button {
        border: 1px solid var(--main-color);
        padding: 4px 10px;
        color: var(--main-color);
        background-color: #fff;
        border-radius: 3px;

        &:hover {
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }
  }

  .main-body {
    padding: 0 10px;

    .main-table {
      background-color: #fff;
      border-radius: 3px;
      width: 100%;

      thead {
        color: #888B94;

        tr {
          th {
            padding: 10px;

            &:first-child {
              text-align: center;
            }
          }
        }
      }

      tbody {
        //border: 1px solid #aaa;

        tr {
          border-bottom: 1px solid rgba(230, 230, 230, .3);

          &:hover {
            background-color: rgba(250, 250, 250, .9);
          }

          td {
            padding: 2px;
            //border: 1px solid #333;
            color: #444749;

            img {
              width: 50px;
              height: 50px;
            }

            img[class="image-bigger"] {
              width: 90px;
              height: 60px;
              margin: 2px;
            }

            &:first-child {
              text-align: center;
            }

            .delete-btn,
            .edit-btn {
              border: none;
              background-color: transparent;
            }

            .delete-btn:hover {
              svg {
                fill: #b40c0c;
              }
            }

            .edit-btn:hover {
              svg {
                fill: #ffcc16;
              }
            }

          }

          .services-text {
            max-width: 370px !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 30px;
          }

          .images-container {
            width: 300px;

            span {
              font-size: 1.4em;
              padding-left: 3px;
              font-weight: bolder;
              letter-spacing: 1px;
              color: #888;

            }

            img {
              border-radius: 50px;
              margin-left: -10px;
            }
          }
        }
      }
    }
  }

  .modal-back {
    background-color: rgba(120, 120, 120, .5);
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9990;
  }

  .save-modal-container {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: center;

    .save-modal {
      width: 500px;
      padding: 0 10px;
      //height: 320px;
      box-shadow: 0 0 12px rgba(140, 140, 140, .4);
      background-color: #fff;

      .save-modal-head {
        color: var(--main-color);
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;

        .save-modal-head__title {
          padding: 15px 17px 6px 17px;
        }

        .save-modal-head__close {
          width: 25px;
          text-align: center;
          height: 40px;
          cursor: pointer;

          svg {
            margin: 15px 0;
            width: 16px;
          }

          &:hover {
            svg {
              fill: red;
            }
          }

        }
      }

      .save-modal-body {
        form {
          border: none;
          padding: 20px;

          label {
            margin-bottom: 5px;
            cursor: pointer;
            display: block;

            &:first-child {
              margin-top: -9px;

            }
          }

          input, textarea {
            width: 100%;
            padding: 3px 7px;
            margin-top: 4px;
            height: 35px;
            border: none;
            border-radius: 0 !important;
            text-indent: 10px;
            box-shadow: 0 0 2px rgba(80, 80, 80, .9);

            &[type="file"] {
              cursor: pointer;

              &::-webkit-file-upload-button {
                visibility: hidden;
                display: none;
              }
            }
          }

          textarea {
            min-height: 80px;
            max-height: 170px;
          }

          .save-modal-body__btn {
            background-color: var(--main-color);
            transition: .1s;
            margin-top: 7px;
            padding: 7px 0;
            width: 100%;
            border: none;
            color: #fff;

            &:hover {
              background-color: #1E4058;
            }

            &:active {
              transform: scale(.95);
            }

          }
        }
      }
    }

    .big-modal {
      width: 950px;

      .save-modal-body__form {
        display: flex;
        gap: 15px 50px;

        .save-modal-form__right,
        .save-modal-form__left {
          display: flex;
          gap: 15px;
          width: 280px;
          flex-direction: column;

          .images-box {
            display: block;
            height: 34px;
            border: 1px solid purple;
            margin-top: 4px !important;
            transition: .07s;
            text-indent: 15px;
            box-shadow: 0 0 2px rgba(80, 80, 80, .9);
            border-radius: 3px;

            &:hover {
              box-shadow: 0 0 0 2px #288AB9;
            }
          }

          .upload-btn {
            border: none;
            width: 100%;
            background-color: #fff;
            height: 35px;
            margin-top: 4px;
            box-shadow: 0 0 2px rgba(80, 80, 80, .9);
          }

          input[type="file"] {
            visibility: hidden;
            position: relative;
            top: -44px;
          }

          textarea {
            resize: none;
            text-indent: 0;
            height: 107px;
          }
        }

        .save-modal-form__right {
          flex-grow: 1;

          .save-modal-form__images {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            margin-top: -13px;
            //padding-top: 18px;
            //border: 1px solid red;

            .save-modal-form__image {
              img {
                width: 90px;
                height: 52px;

              }

              &:hover {
                opacity: 0.7;

                img {

                  & + .remove-img {
                    visibility: visible;
                  }
                }
              }

              .remove-img {
                visibility: hidden;
                background-color: #fff;
                width: 28px;
                height: 28px;
                transform: scale(.9);
                position: absolute;
                margin-left: -59px;
                margin-top: 12px;
                cursor: pointer;
                transition: .07s;
                border-radius: 50px;
                text-align: center;

                &:hover {
                  transform: scale(1);
                  visibility: visible;
                }

              }

              .remove-img svg {
                width: 20px;
              }
            }
          }
        }
      }

      .save-modal__foot {
        //border: 1px solid red;
        text-align: right;
        padding: 14px 0 10px 0;

        button.save-modal-body__btn {
          padding: 7px 45px;
          transition: .1s;
          width: auto;

          &:hover {
            background-color: #1E4058;
          }

          &:active {
            transform: scale(.95);
          }
        }
      }
    }
  }

  .delete-modal-container {
    position: absolute;
    top: 180px;
    left: 0;
    z-index: 9999;
    width: 100%;
    display: flex;
    justify-content: center;

    .delete-modal {
      width: 300px;
      padding: 0 10px 20px 10px;
      position: absolute;
      box-shadow: 0 0 12px rgba(140, 140, 140, .4);
      background-color: #fff;

      .delete-modal-head {
        color: var(--main-color);
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;

        .delete-modal-head__title {
          user-select: none;
          padding: 15px 17px 6px 17px;
          color: #B40C0C;
        }

        .delete-modal-head__close {
          width: 25px;
          text-align: center;
          user-select: none;
          height: 40px;
          cursor: pointer;

          svg {
            margin: 15px 0;
            width: 16px;
          }

          &:hover {
            svg {
              fill: red;
            }
          }

        }
      }

      .delete-modal-foot {
        display: flex;
        justify-content: space-around;
        column-gap: 10px;
        padding: 0 10px;

        button {
          border: none;
          background-color: var(--main-color);
          color: #fff;
          margin-top: 17px;
          width: 100%;
          padding: 7px 0;
          opacity: .8;

          &[class="delete-modal__delete-btn"] {
            background-color: #B40C0C;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.modal-visible {
  visibility: visible;
  transition: .08s;
  opacity: 1;
  transform: scale(1);
}

.modal-hidden {
  visibility: hidden;
  transition: .08s;
  opacity: 0;
  transform: scale(.9);
}
