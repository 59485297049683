.fa-solid {
  cursor: pointer;
  margin-left: 197px;
  font-size: 25px;
  color: red;
}

.about-head {
  display: flex;
}

.modal-title {
  font-weight: 700;
  color: #207fa8 !important;
  margin: auto;
  text-transform: uppercase;
  text-align: left;
  margin-left: 28px;
}

.modal-img {
  width: 400px;
  justify-content: center;
  align-items: center;
  margin: auto auto auto auto;
  padding: 5px 10px;
  border-radius: 20px;
  margin-left: 20px;
}

.modal-description {
  align-content: center;
  color: #2783af !important;
  justify-content: center;
  margin-bottom: 15px;
}

/* .modal-scroll {
  overflow-y: scroll;
  height: 135px;
} */

.modal-about {
  width: 465px;
  text-align: justify;
  font-size: 15px;
  font-weight: 700;
  color: #9ea0a8;
}

.ReactModal__Overlay {
  background: none !important;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
