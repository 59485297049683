:root {
  --main-color: #288AB9;
  --main-text-color: #207fa8;
  --main-body-color: #192232;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: var(--main-body-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--main-text-color);
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
}

body::-webkit-scrollbar-thumb:hover {
  box-shadow: 0 0 15px 4px var( --main-text-color);
}