.footer {
  background-color: var(--main-body-color);
  color: #eee;
  padding: 48px 80px !important;
  background-image: url("../../utilities/images/background-logo.svg");
  background-repeat: no-repeat;
  background-position: 0 -30px;
  margin-top: 100px;

  .footer-logo {
    margin-bottom: 27px;
  }

  .footer-description {
    padding-right: 165px;
    margin-bottom: 27px;
  }

  .footer-information {
    .footer-information-title {
      font-weight: bold;
      margin-bottom: 16px;
    }

    a {
      display: block;
      padding: 4px 0;
      text-decoration: none;
      color: #eee;
    }
  }

  .footer-contact {
    .footer-contact-title {
      margin-bottom: 16px;
    }

    .footer-contact-tel,
    .footer-contact-email {
      padding: 4px 0;

      a {
        text-decoration: none;
        color: #fff;

        svg {
          margin-right: 6px;
          margin-top: -2px;

          path {
            fill: #fff;
          }
        }

        &:hover {
          color: #ccc;

          svg {
            path {
              fill: #ccc;
            }
          }
        }
      }
    }

  }

  .footer-social-nets {
    display: flex;
    column-gap: 25px;
    padding-top: 23%;
  }
}